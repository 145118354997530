<!-- 所搜框 -->
<template>
    <div class="search">
        <div class="search-main">
            <div class="search-select" @click.stop="show = true">
                <div class="search-select-text">筛选</div>
                <i class="el-icon-caret-bottom" :class="show?'icons':''"></i>
                <div class="search-select-bar" v-if="show" data-aos="fade-right" :data-aos-delay="0">
                    <div class="search-select-bar-item" v-for="item,index in initPomer" :key="index">
                        <template v-if="item.type =='radio' && item.show">
                            <div class="search-select-bar-item-title"> {{item.title}} </div>
                            <div class="search-select-bar-item-conter">
                                <el-radio-group v-model="item.value">
                                    <el-radio :label="key.value" v-for="key,i in item.list" :key="i">{{key.label}}</el-radio>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-if="item.type =='datePicker' && item.show">
                            <div class="search-select-bar-item-title"> {{item.title}} </div>
                            <div class="search-select-bar-item-conter">
                                <el-date-picker v-model="item.value" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                        </template>
                        <template v-if="item.type =='select' && item.show">
                            <div class="search-select-bar-item-title"> {{item.title}} </div>
                            <div class="search-select-bar-item-conter">
                                <el-select v-model="item.value" placeholder="请选择状态查询">
                                    <el-option :value="key.value" :label="key.label" v-for="key,i in item.list" :key="i"></el-option>
                                </el-select>
                            </div>
                        </template>
                        <template v-if="item.type =='checkbox' && item.show">
                            <div class="search-select-bar-item-title"> {{item.title}} </div>
                            <div class="search-select-bar-item-conter">
                                <el-checkbox-group v-model="item.value">
                                    <el-checkbox v-for="key,i in item.list" :key="i" :label="key.value">{{key.label}}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="search-input">
                <input v-model="value" @keyup.enter="enterSearch" placeholder="请输入查询内容">
            </div>
            <div class="search-icon">
                <i class="el-icon-search"></i>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props:{
        initPomer:{
            type:Array,
            default:[{//单选
                type:'radio',
                show:false,
                title:'单项选择',
                list:[
                    {value:'1',label:'默认'},
                    {value:'2',label:'全部'},
                    {value:'3',label:'未处理'}
                ],
                value:''
            },{//多选
                type:'checkbox',
                show:false,
                title:'多项选择',
                list:[
                    {value:'1',label:'默认'},
                    {value:'2',label:'全部'},
                    {value:'3',label:'未处理'}
                ],
                value:[]
            },{//select单选
                type:'select',
                show:false,
                title:'select选择',
                list:[
                    {value:'1',label:'默认'},
                    {value:'2',label:'全部'},
                    {value:'3',label:'未处理'}
                ],
                value:''
            },{//时间区间选择
                type:'datePicker',
                show:false,
                title:'时间选择',
                value:[]
            }]
        },
    },
    data() {
        return {
            show: false,
            value:''
        }
    },
    watch:{
        initPomer:{
            handler(newData){
                this.$emit('Searchs',newData)
            },deep:true
        }
    },
    methods:{
        enterSearch(){
            console.log('回车搜索',this.value)
        },
    },
    mounted() {
        const vm = this
        document.body.addEventListener('click', function(event) {
            vm.show = false
        });
    }
}
</script>
<style scoped>
::v-deep .el-input__inner {
    background-color: #183d7d !important;
    border: 1px solid #24509b;
    color: #5fc5ff;
    text-align: center;
}
::v-deep .el-form-item__label{
    color: #5fc5ff !important;

}
::v-deep .el-input__inner:focus {
    border: 1px solid #24509b;
}
::v-deep .el-tabs__item {
    color: #5fc5ff;
}

::v-deep .el-tabs__item.is-active {
    color: #5fc5ff !important;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: #183d7d;
}

::v-deep .el-form-item__label {
    color: #5fc5ff;
}

::v-deep .el-input__inner {
    background-color: #183d7d;
    border-radius: 4px;
    border: 1px solid #183d7d;
    color: #fff;
}

::v-deep .el-switch.is-checked .el-switch__core {
    border-color: #183d7d;
    background-color: #183d7d;
}

::v-deep .el-switch__core {
    border: 1px solid #f56c6c;
    background: #f56c6c;
}

::v-deep .el-radio {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px;
}
</style>
<style scoped>
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
    border-bottom: 1px solid rgba(133, 170, 233, 0.08);
}

::v-deep .el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(133, 170, 233, 0.08) !important;
}

/* 设置 el-table 的背景色为透明 */
::v-deep .el-table {
    background-color: transparent !important;
    border:1px solid rgba(133, 170, 233, 0.1);
}

/* 设置 el-table 的表头背景色 */
::v-deep .el-table__header-wrapper th {
    background-color: rgba(133, 170, 233, 0.08) !important;
    color: #03aff6;
}

::v-deep .el-form-item__content {
    line-height: 0;
}

::v-deep .el-table tr {
    background-color: transparent !important;

}

/* 设置表格行的背景色为透明 */
::v-deep .el-table__body tr {
    background-color: transparent !important;
    color: #03aff6;
}

/* 如果需要，也可以设置鼠标悬停时的行背景色为透明 */
::v-deep .el-table__body tr:hover>td {
    background-color: rgba(133, 170, 233, 0.08) !important;
}

::v-deep .el-table__cell {
    /* border-bottom: 1px solid rgba(133, 170, 233, 0.08); */
}


/**去掉table底部的横线 */
::v-deep .el-table::before {
    height: 0;
}


::v-deep .el-checkbox__inner {
    background-color: transparent !important;
    border: 1px solid #03aff6;
}

/* 修改勾选框的伪元素颜色（如果需要） */
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    border-color: #03aff6;
    /* 勾选标记的颜色 */
}


::v-deep .el-date-editor .el-range-input {
    background: #183d7d;
    color: #03aff6;
}
::v-deep .el-date-editor .el-range-separator {
    color: #03aff6;
}
</style>
<style scoped>
::v-deep .el-drawer {
    background-color: #001440 !important;
}

::v-deep .el-drawer__header {
    color: #5fc5ff;
    justify-content: center;
}

::v-deep .el-drawer__header>:first-child {
    flex: 1;
    font-size: 20px;
    font-weight: bold
}
::v-deep .el-input__inner {
    text-align: left !important;
}
::v-deep .el-date-editor .el-range-input {
    color: #fff;
    background: transparent;
}
::v-deep .el-date-editor .el-range-separator {
    color: #5fc5ff;
}
::v-deep .el-checkbox {
    color: #fff;
}
</style>
<style lang="scss">
.search {
}
.icons{
    transition: all .2s;
    transform: rotate(-180deg);
}
.search-main{
    width: 400px;
    height: 30px;
    background: rgba(#072969, .5);
    border: 1px solid #034188;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-left: 20px;
    position: relative;
}
.search-select {
    border-right: 1px dashed #01a0eb;
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    color: #01a0eb;
    cursor: pointer;
}

.search-select-text {
    font-size: 12px;
}

.search-select-bar {
    position: absolute;
    left: 0;
    top: 35px;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    background: rgba(#072969, .5);
    border: 1px solid #034188;
}

.search-select-bar-item {
    margin: 20px 10px;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgba(#01a0eb, .5);
}

.search-select-bar-item:last-child {
    border: none;
}

.search-select-bar-item-title {
    font-size: 14px;
    padding: 10px 0;
}

.search-select-bar-item-conter {
    padding: 10px 0;
}

.search-input {
    flex: 1;
    height: 100%;
    padding: 0 10px;
}

.search-input>input::placeholder {
    color: #01a0eb;
}

.search-input>input {
    min-width: 100%;
    min-height: 100%;
    outline: none;
    background: none;
    border: none;
    color: #01a0eb;
}

.search-icon {
    color: #01a0eb;
}
</style>