<!-- 报警数据 -->
<template>
    <div class="alarmData">
        <!-- <router-link to="/intelligentsystem/alarmData/alarmData2">111</router-link> -->
        <searchs :initPomer="initPome" @Searchs="enterSearch"></searchs>
        <chartData></chartData>
    </div>
</template>
<script>

import chartData from "./chartData.vue";

import searchs from "./search.vue";
export default {
    components:{searchs,chartData},
    data(){
        return {
            input3: '',
            select: '',
            initPome: [{
                type:'radio',
                show:true,
                title:'单项选择',
                list:[
                    {value:'1',label:'默认11'},
                    {value:'2',label:'全部22'},
                    {value:'3',label:'未处理33'}
                ],
                value:''
            },{
                type:'checkbox',
                show:true,
                title:'多项选择',
                list:[
                    {value:'1',label:'默认11'},
                    {value:'2',label:'全部22'},
                    {value:'3',label:'未处理33'}
                ],
                value:[]
            },{
                type:'select',
                show:true,
                title:'select选择',
                list:[
                    {value:'1',label:'默认11'},
                    {value:'2',label:'全部22'},
                    {value:'3',label:'未处理33'}
                ],
                value:''
            },{
                type:'datePicker',
                show:true,
                title:'时间选择',
                value:[]
            }],
        }
    },
    methods:{
        enterSearch(data){
            console.log('数据搜索',data)
        }
    }
}
</script>
